<script>
import { ref } from 'vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'layout-rounded',
  setup(_, { emit }) {
    const scrollarea = ref(null)
    const hasShadow = ref(false)
    const showBackToTop = ref(false)

    const { haveRightWidget } = useEditablePage()
    const { isBillPaymentsV15Template } = useProvider()

    function onScroll(event) {
      if (event.target?.scrollTop > 500) {
        showBackToTop.value = true
      } else {
        showBackToTop.value = false
      }

      hasShadow.value = event.target?.scrollTop > 0

      emit('scroll', event)
    }

    function scrollTop() {
      scrollarea.value.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return {
      hasShadow,
      scrollarea,
      showBackToTop,
      onScroll,
      scrollTop,
      haveRightWidget,
      isBillPaymentsV15Template,
    }
  },
}
</script>

<template>
  <div
    class="relative flex h-full flex-col sm:overflow-hidden"
    :class="{
      'rounded-t-3xl': haveRightWidget,
      'md:mr-5': !haveRightWidget && !isBillPaymentsV15Template,
      'sm:rounded-tl-3xl md:rounded-t-3xl': !isBillPaymentsV15Template,
    }"
  >
    <div
      v-if="!$slots.header"
      class="pointer-events-none absolute top-0 z-10 block h-[30px] w-full rounded-inherit shadow-inner transition duration-300"
      :class="hasShadow ? 'opacity-100' : 'opacity-0'"
    />

    <div
      class="rounded-inherit sm:flex sm:h-full sm:flex-col sm:bg-eonx-neutral-50"
    >
      <div
        v-if="$slots.header"
        class="transition-color border-b duration-300"
        :class="{
          'border-transparent': !hasShadow,
        }"
      >
        <slot name="header" />
      </div>

      <div
        ref="scrollarea"
        class="grow overflow-x-hidden sm:overflow-x-auto sm:overflow-y-auto"
        @scroll="onScroll"
      >
        <slot />
      </div>
    </div>

    <slot
      name="back-to-top"
      :show-back-to-top="showBackToTop"
      :scroll-top="scrollTop"
    />
  </div>
</template>
